import { useMemo } from 'react';

import { ExternalFeature } from '../../app/app-loader/hooks/useLoadExternalFeatures';
import { LtiAppDefinition } from '../../app/app-loader/hooks/useLoadLtiAppDefinitions';
import { ltiAppNames } from '../../common/ltiAppNames';
import { getCardDefinitions } from '../cards';
import { Card } from '../components/Card';

export const useFilteredCards = (
    accountId: string,
    ltiAppDefinitions: Record<ltiAppNames, LtiAppDefinition>,
    permissions: string[],
    externalFeatures: ExternalFeature[]
) => {
    const cards = useMemo(() => {
        let cardDefinitions = getCardDefinitions(accountId, ltiAppDefinitions);

        cardDefinitions = filterBasedOnAvailableLtiApps(cardDefinitions, ltiAppDefinitions);
        cardDefinitions = filterBasedOnPermissions(cardDefinitions, permissions);
        cardDefinitions = filterBasedOnExternalFeatureAvailability(cardDefinitions, externalFeatures);
        cardDefinitions = filterEmptyCards(cardDefinitions);
        cardDefinitions = filterLtiUsageByImpactAvailability(cardDefinitions, permissions);

        return cardDefinitions;
    }, [accountId, ltiAppDefinitions, permissions, externalFeatures]);

    return cards;
};

function filterBasedOnAvailableLtiApps(
    cardDefinitions: Card[],
    ltiAppDefinitions: Record<ltiAppNames, LtiAppDefinition>
) {
    return cardDefinitions.map((card) => ({
        ...card,
        menus: card.menus.filter((menu) => !menu.ltiAppName || !!ltiAppDefinitions[menu.ltiAppName]),
    }));
}

function filterEmptyCards(cardDefinitions: Card[]) {
    return cardDefinitions.filter((card) => card.menus.length > 0);
}

function filterBasedOnPermissions(cardDefinitions: Card[], permissions: string[]) {
    return cardDefinitions.map((card) => ({
        ...card,
        menus: card.menus.filter((menu) => menu.permission == null || permissions.includes(menu.permission)),
    }));
}

function filterBasedOnExternalFeatureAvailability(cardDefinitions: Card[], externalFeatures: ExternalFeature[]) {
    return cardDefinitions.map((card) => ({
        ...card,
        menus: card.menus.filter(
            (menu) =>
                menu.externalFeatureName == null ||
                externalFeatures.some((feature) => feature.name === menu.externalFeatureName && feature.enabled)
        ),
    }));
}

function filterLtiUsageByImpactAvailability(cardDefinitions: Card[], permissions: string[]) {
    const isImpactAvailable = permissions.includes('view_lti_insights');
    if (!isImpactAvailable) {
        return cardDefinitions;
    }
    return cardDefinitions.map((card) => ({
        ...card,
        menus: card.menus.filter((menu) => menu.permission !== 'view_lti_usage'),
    }));
}
